
import SupplierSelector from '@/components/forms/SupplierSelector.vue'
import api from '@/utilities/api'
import { Component, Vue, Watch } from 'vue-property-decorator'

@Component({
  components: {
    SupplierSelector,
  },
})
export default class Profile extends Vue {
  // data // ***
  complianceRules = []
  isLoading = false

  get supplier(): Record<string, any> {
    return this.$store.getters['suppliers/selectedSupplier']
  }

  // watch // ***
  @Watch('features')
  onFeaturesChanged(): void {
    this.redirectIfDisabled()
  }

  // created // ***
  created() {
    this.redirectIfDisabled()
    this.fetchComplianceStatus()
  }

  // methods // ***
  redirectIfDisabled() {
    if (this.$store.getters['core/featureDisabled']('compliance')) this.$router.push('/errors/404')
  }

  async fetchComplianceStatus() {
    this.isLoading = true
    const { data } =
      (await api(
        'supplier/complianceRequirements/isCompliant/' + this.supplier?.id,
        undefined,
        undefined
      )) || {}
    if (data) this.complianceRules = data
    this.isLoading = false
  }
}
